import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import BgImage from '../images/brand-list.jpg'
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton";
import { StaticImage } from "gatsby-plugin-image"
import BreakpointDown from "../components/Media/BreakpointDown";


const SectionBgAfter = styled(Section)`
 &:after{
   right:0;
   left:0;
   background-size:cover;
 }
 .container{
   z-index:1;
 }
`
const PageTitle = styled.h1`
 margin-bottom:0;
 text-transform: capitalize;
 text-align:center;
`
const BrandLogo = styled.div`
 position:relative;
 margin-left:auto;
 margin-right:auto;
 max-width:200px;
 margin-top:10px;
`
const PageTitleWrapper = styled.div`
text-align:center;
font-size:16px;
img{ margin-top:10px;}
p{ margin-bottom:0;}
`
const SectionTitle = styled.h2`
 margin-bottom:0;
`
const BrandWrapper = styled.div`
  &+&{
    margin-top:100px;
    ${BreakpointDown.md`
     margin-top:40px;
    `}
  }
`
// blog Card 

const BrandRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-left:-20px;
 margin-right:-20px;
 ${BreakpointDown.lg`
  margin-left:-15px;
  margin-right:-15px;
 `}

`
const BrandColumn = styled.div`
 flex:0 0 100%;
 width:100%;
 padding-left:20px;
 padding-right:20px;
 margin-top:40px;
 ${BreakpointDown.lg`
   padding-left:15px;
 padding-right:15px;
 `}
 ${BreakpointDown.md`
  margin-top:30px;
 `}
`

const Card = styled.div`
  padding: 70px 62px;
  background:#fff;
  display:flex;
  height:100%;
  align-items:center;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  @media(max-width:1440px){
    padding: 15px;
  }
  ${BreakpointDown.md`
   text-align:center;
   flex-wrap:wrap; 
 `}
  .btn{
    margin-top:0px;
  }
`
const CardTitle = styled(Link)`
 margin-bottom:6px;
`
const CardImg = styled.div`
  position: relative;
  max-width:200px;
  width:100%;
  margin-top:26px;
  ${BreakpointDown.md`
     max-width:200px;
     margin-top:0px;
     margin-left:auto;
     margin-right:auto; 
  `}
  .gatsby-image-wrapper{ 
    width:100%;
  }
`
const BrnadDesciption = styled.div`
  display:flex;
  flex-direction:column;
  padding:30px 30px 30px 130px;
${BreakpointDown.lg`
   padding:15px;
`}
${BreakpointDown.md`
   padding:0;
   margin-top:15px;
`}
p{ font-size:16px;}
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3; 
`
function BrandListPage() {
  return (
    <Layout pageName="brand-list">
      <SEO
        title="BROTHER: Office Printer Solutions for Businesses"
        description="WYN Technologies provides Brother printing solutions such as All-in-one Printers, Mobile Printers, Scanners, Labelling Devices and more to Businesses of all sizes."/>
      <SectionBgAfter pt="156px" pb="100px" xpt="90px" xpb="60px" mpt="60px" mpb="40px" bgAfter={`url(${BgImage})`}>
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link> <Link to="/#">brother</Link>
          </BreadCrumb>
          <PageTitle>BROTHER: Office Printer Solutions</PageTitle>
          <BrandLogo>
            <StaticImage
              src="../images/brand-1.png"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Letast Article"
              placeholder="blurred"
            />
          </BrandLogo>
          <p><strong>BROTHER</strong> products have been helping businesses do what they do better for generations. Their reliable, dependable, high-quality, printers, fax machines, mobile solutions, and other products are hard at work in businesses all across America, and all over the globe. BROTHER is probably best known for its printers and sewing machines, but the BROTHER family of products also includes a range of innovative solutions for industrial, manufacturing, machine tool, and commercial enterprises. Whatever you may be looking for, BROTHER products are here to serve "At Your Side."</p>
        </div>
      </SectionBgAfter>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Why BROTHER?</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <p>BROTHER’s roots can be traced back to the Yasui Sewing Machine Company founded in Japan way back in 1908. Out of these beginnings, the Brother International Corporation was then established in New Jersey in 1955, and today BROTHER is now a truly multinational electronics and electrical equipment company. BROTHER’s mission is to live out their “At Your Side” promise to simplify and enrich the lives of customers, employees, and communities alike. You’re likely familiar with some of BROTHER’s home office and home appliance products, but BROTHER also offers a full range of business, commercial, and industrial product solutions.</p>
                <p>Quality is at the heart of everything BROTHER does – from research & development, to manufacturing & materials, to products & services, to personnel & supplier partners. Do you like purchasing from a business that’s always working to anticipate future needs and to deliver the solutions that will meet those needs? Then you’re sure to love BROTHER’s user-friendly family of product offerings.</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>

      {/* <Section pt="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8" pb="100px" xpb="60px">
        <div className="container">
          <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle className="h1">Products</SectionTitle>
              <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p>
            </PageTitleWrapper>
            <BrandRow>
             
                <BrandColumn>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">ZIP 43GExceptional Value in a Feature Rich IP Phone</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
            
            </BrandRow>
          </BrandWrapper>
         <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle to="/brand-detail">IP Phone Systems</SectionTitle>
              <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p>
            </PageTitleWrapper>
            <BrandRow>
             
                <BrandColumn>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">ZIP 43GExceptional Value in a Feature Rich IP Phone</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
            
            </BrandRow>
          </BrandWrapper>
        </div> 
      </Section>*/}
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px" bgColor="rgb(248, 248, 248)">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">A Few Examples of BROTHER Products We Install & Configure at WYN Technologies</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <h3>PRINTERS & ALL-IN-ONES</h3>
                <p>Print smart and optimize your office with BROTHER’s range of quality, state-of-the-art machines to suit every need, as well as every budget. They’ve spent years perfecting the office printer; all you have to do is pick the right one for your business.</p>
                 <ul>
                  <li>
                    <strong>Home Office / Small Office Printers & All-in-Ones</strong>
                    <p>Both inkjet and laser printers for home office and small business environments. Options include wireless printing and two-sided printing. No matter your document imaging needs, BROTHER has a solution for your small business or startup. Top sellers include the MFC-L2710DW, MFC-L3770CDW, and MFC-L8900CDW models.</p>
                  </li>
                  <li>
                    <strong>Business / Business Workgroup Printers & All-in-Ones</strong>
                    <p>These machines are designed for handling larger print volumes, up to 10,000 pages per month. Key features include a lower cost per page, paper expandability, advanced scanning capabilities, and enhanced security measures. Place one of these BROTHER All-in-Ones at every workgroup station; it’s easy to scale up as your business grows, too. Top sellers include the MFC-L8900CDW, HL-L8360CDW, and MFC-L5850DW models. And every machine in the BROTHER Workhorse Series is designed to reliably meet the needs of any mid-sized business.</p>
                  </li>
                  <li>
                    <strong>Labeling Applications</strong>
                    <p>Looking for the right labeling application for your specific business or office need? BROTHER’s QL label printers are ideal for everything from retailing & shipping labeling, to visitor management solutions, to custom software applications.</p>
                  </li>
                  </ul>
                </MarkdownContent>
                <MarkdownContent>
                <h3>MOBILE PRINTERS & LABELERS</h3>
                <p>If your business isn’t limited by walls, then you need the right tools to empower and enable your on-the-go workforce. BROTHER’s high-performance mobile printing and labeling products are designed to meet your unique needs.</p>
                 <ul>
                  <li>
                    <strong>Mobile Printers</strong>
                    <p>Print on-the-move with these versatile, portable printers. Produce everything from labels, to receipts, to tags, all the way up to high-quality documents. The top-selling RJ4230BL model is a rugged 4” printer which is perfect for labels and receipts. There are also some great full-page mobile printing options, including the PJ673-K, PJ723-VK, and PJ773-DK models.</p>
                  </li>
                  <li>
                    <strong>Handheld Printers</strong>
                    <p>Need some reliable P-touch printers for your business? You can print durable laminated labels anywhere you need them with BROTHER’s P-touch EDGE line of portable handheld printers. Popular models include the EDGE PTE550W, EDGE PTE500, and EDGE PTE300.</p>
                  </li>
                  <li>
                    <strong>Desktop Thermal Printers</strong>
                    <p>Bridge the gap between desktop and mobile printing with these compact, versatile, powerful, and feature-packed thermal desktop printers from BROTHER. Popular models include the PT-D600VP, QL-1100, and PF-D400VP.</p>
                  </li>
                  </ul>
                </MarkdownContent>
                 <MarkdownContent>
                <h3>SCANNERS</h3>
                <p>Need a dedicated desktop scanner or portable scanner to help streamline your workflow? BROTHER’s offering of scanner products ranges from compact and efficient to fully automated.</p>
                 <ul>
                  <li>
                    <strong>Home Office / Small Office Scanners</strong>
                    <p>Clear the clutter, get organized, and set your small office up for greater success. Use these scanners from BROTHER to easily capture, manage, retrieve, and share the information that’s most important for your business. Top sellers include the ADS-2700W, ADS-1700W, and ADS-2200 models.</p>
                  </li>
                  <li>
                    <strong>Workgroup Scanners</strong>
                    <p>Need a scanner solution that’s specially designed to meet the needs of small to midsize workgroups? BROTHER offers several good product options to meet your workflow demands. These business scanners can integrate easily into your particular work environment, while also ensuring the security of your digitized documents at the same time. Popular models include the ImageCenter ADS-2800W, the ImageCenter ADS-3000N, and the ImageCenter ADS-3600W.</p>
                  </li>
                  <li>
                    <strong>FAX MACHINES</strong>
                    <p>Think fax machines are a thing of the past? Think again. Many businesses and organizations still prefer to send and receive documents by fax, and BROTHER offers high-performance fax products with capabilities for any budget or need. Whether you operate a home office, a small business facility, or a large enterprise, we’ve got your needs covered.</p>
                  </li>
                  </ul>
                </MarkdownContent>
             </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
      
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Got Questions? Ready to Get Started? WYN Technologies Can Help!</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                 <p>Reach out to the pros at WYN Technologies today, and let’s discuss your goals, needs, and intended applications for printing, imaging, and document sharing equipment. We’re happy to deliver, install, and configure whatever BROTHER products you may need, but we can also do a lot more than just that. We actually offer a full slate of business technology solutions. It’s really all in our name – WYN is here to provide Whatever You Need in terms of the technology and equipment required to take your business or commercial organization to the next level! Call us today at <a href="tel:3368990555">(336) 899-0555</a>.</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>

    </Layout>
  )
}
export default BrandListPage
